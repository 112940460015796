<template>
  <div>
    <div style="float:right;margin-top: -40px;"><el-button size="small" @click="toggleBeautify">Reformat Code</el-button></div>
    <prism-editor
        class="my-editor height mb-4"
        v-model="editorCode"
        :highlight="highlighter"
        line-numbers
        @input="codeChanged"
        :tabSize="1"
        :insertSpaces="false"
    ></prism-editor>
  </div>
</template>

<script>
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import prism from "prismjs";
import "prismjs/themes/prism-tomorrow.css"; // import syntax highlighting styles

const beautify = require("js-beautify").js;
const beautify_html = require('js-beautify').html;
const beautify_css = require('js-beautify').css;

export default {
  name: "CodeEditor",
  components: {
    PrismEditor,
  },
  props: {
    markup: {
      type: String,
      default: null
    }
  },
  mounted() {
  },
  watch: {
    beautifyCode: function () {
      this.updateCode()
    },
    markup: function () {
      this.updateCode()
    }
  },
  data() {
    return {
      editorCode: null,
      beautifyCode: false,
    }
  },
  emits: ['codeChanged'],
  methods: {
    toggleBeautify() {
      this.beautifyCode = !this.beautifyCode
    },
    highlighter(code) {
      return prism.highlight(code, prism.languages.js, 'php'); // languages.<insert language> to return html with markup
    },
    codeChanged(value) {
      this.$emit("codeChanged", this.editorCode);
    },
    updateCode() {
      if(!this.editorCode) {
        this.editorCode = this.markup
      }
      if(this.beautifyCode) {
        // this.editorCode = beautify_css(this.editorCode, { indent_with_tabs: true, indent_size: 1})
        this.editorCode = beautify_html(this.editorCode, {
          "indent_size": "1",
          "indent_char": "\t",
          "max_preserve_newlines": "0",
          "preserve_newlines": true,
          "keep_array_indentation": false,
          "break_chained_methods": false,
          "indent_scripts": "separate",
          "brace_style": "collapse",
          "space_before_conditional": true,
          "unescape_strings": false,
          "jslint_happy": false,
          "end_with_newline": false,
          "wrap_line_length": "0",
          "indent_inner_html": false,
          "comma_first": false,
          "e4x": false,
          "indent_empty_lines": false
        })
        this.beautifyCode = false
      }
    }
  },
}
</script>

<style scoped lang="scss">
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}

.height {
  height: 400px;
}

.mb-4 {
  margin-bottom: 1rem;
}
</style>
